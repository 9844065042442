/** @typedef {{
      (count: number): (x: TemplateStringsArray) => string;
      (count: number, args: object): (x: TemplateStringsArray) => string;
      (x: TemplateStringsArray): string;
    }} Translate */
/** @type {React.Context<{ language: string, translate: Translate }>} */
const i18nContext = React.createContext({ language: null, translate: null })

export function I18nProvider({ language, children, translateSingular, translatePlural }) {
  const translate = React.useCallback(
    (x, ...args) => {
      if (typeof x === 'number')
        return ([id]) => translatePlural(language, id, x, ...args)
      if (typeof x === 'object' && !Array.isArray(x))
        return ([id]) => translateSingular(language, id, x, ...args)
      else {
        const [id] = x
        return translateSingular(language, id)
      }
    },
    [language, translatePlural, translateSingular]
  )

  const value = React.useMemo(() => ({ translate, language }), [translate, language])

  return <i18nContext.Provider {...{ children, value }} />
}


export function useLanguage() {
  const { language } = React.useContext(i18nContext)
  return language
}

export function useTranslate() {
  const { translate } = React.useContext(i18nContext)
  return { __: translate }
}
